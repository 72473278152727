import { defineStore } from 'pinia'
import { mockProducts } from '../data/mockProducts'
import type { Product } from '../types/interfaces'

export const useProductStore = defineStore('products', {
  state: () => ({
    products: [] as Product[]
  }),

  getters: {
    getProducts: (state) => state.products
  },

  actions: {
    async fetchProducts() {
      console.log('Fetching products...')
      this.products = mockProducts
      console.log('Products loaded:', this.products)
    }
  }
}) 