import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "hero-section" }
const _hoisted_2 = { class: "hero-content" }
const _hoisted_3 = { class: "hero-actions" }
const _hoisted_4 = {
  id: "featured",
  class: "featured-section"
}
const _hoisted_5 = { class: "featured-container" }
const _hoisted_6 = { class: "featured-grid" }
const _hoisted_7 = { class: "view-all-container" }

import { onMounted, ref } from 'vue'
import ProductCard from '../components/product/ProductCard.vue'
import { useProductStore } from '../stores/products'
import type { Product } from '../types/interfaces'


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const productStore = useProductStore()
const featuredProducts = ref<Product[]>([])

onMounted(() => {
  productStore.fetchProducts()
  featuredProducts.value = productStore.products.slice(0, 3)
})

const scrollToFeatured = () => {
  document.getElementById('featured')?.scrollIntoView({ 
    behavior: 'smooth',
    block: 'start'
  })
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "hero-title" }, [
          _createTextVNode(" Discover Elegant "),
          _createElementVNode("span", { class: "accent-text" }, "Gold Bracelets")
        ], -1)),
        _cache[3] || (_cache[3] = _createElementVNode("p", { class: "hero-subtitle" }, "Handcrafted with precision, designed for luxury", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_link, {
            to: "/products",
            class: "cta-button"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" Explore Collection ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("button", {
          onClick: scrollToFeatured,
          class: "scroll-button"
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("span", { class: "scroll-text" }, "Discover Featured", -1),
          _createElementVNode("span", { class: "scroll-icon" }, null, -1)
        ]))
      ])
    ]),
    _createElementVNode("section", _hoisted_4, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "section-header" }, [
        _createElementVNode("h2", { class: "section-title" }, "Featured Collections"),
        _createElementVNode("p", { class: "section-subtitle" }, "Curated selection of our finest pieces")
      ], -1)),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(featuredProducts.value, (product) => {
            return (_openBlock(), _createBlock(ProductCard, {
              key: product.id,
              product: product
            }, null, 8, ["product"]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_router_link, {
            to: "/products",
            class: "view-all-button"
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode(" View All Collections "),
              _createElementVNode("span", { class: "arrow-icon" }, "→", -1)
            ])),
            _: 1
          })
        ])
      ])
    ])
  ]))
}
}

})