<template>
  <header class="header-container">
    <nav class="nav-wrapper">
      <router-link to="/" class="nav-logo">
        Wrist Twist
        <span class="nav-logo-accent">.</span>
      </router-link>
      <div class="nav-links">
        <router-link v-for="link in navLinks" :key="link.path" :to="link.path" class="nav-link"
          :class="{ 'nav-link-cart': link.path === '/cart' }">
          {{ link.label }}
          <span v-if="link.path === '/cart'" class="cart-count">
            {{ cartStore.totalItems }}
          </span>
        </router-link>
      </div>
    </nav>
  </header>
</template>

<script setup lang="ts">
import { useCartStore } from '../../stores/cart'

const cartStore = useCartStore()

const navLinks = [
  { path: '/', label: 'Home' },
  { path: '/products', label: 'Products' },
  { path: '/cart', label: 'Cart' }
]
</script>

<script lang="ts">
export default {
  name: 'TheHeader'
}
</script>

<style scoped>
.header-container {
  background-color: rgba(18, 18, 18, 0.98);
  padding: var(--spacing-sm) var(--spacing-md);
  position: sticky;
  top: 0;
  z-index: 100;
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0.5rem 1rem;
}

.nav-logo {
  color: var(--light-color);
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: none;
  letter-spacing: -0.5px;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
}

.nav-logo-accent {
  color: var(--primary-color);
  margin-left: 2px;
}

.nav-logo:hover {
  transform: translateY(-2px);
  transition: transform 0.3s ease;
}

.nav-links {
  display: flex;
  gap: var(--spacing-md);
  align-items: center;
}

.nav-link {
  color: #9ca3af;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-weight: 500;
  font-size: 0.95rem;
  transition: all 0.2s ease;
  position: relative;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: var(--primary-color);
  transition: all 0.3s ease;
  transform: translateX(-50%);
  border-radius: 2px;
}

.nav-link:hover {
  color: var(--light-color);
}

.nav-link:hover::after {
  width: 30%;
}

.nav-link-cart {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 9999px;
  padding: 0.5rem 1.25rem;
  transition: all 0.2s ease;
}

.nav-link-cart:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.cart-count {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 0.15rem 0.6rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 600;
  transition: all 0.2s ease;
}

.nav-link-cart:hover .cart-count {
  transform: scale(1.1);
  background-color: var(--light-color);
}

@media (max-width: 768px) {
  .nav-wrapper {
    padding: 0.25rem 0.5rem;
  }

  .nav-links {
    gap: var(--spacing-sm);
  }

  .nav-link {
    padding: 0.5rem;
    font-size: 0.9rem;
  }

  .nav-link-cart {
    padding: 0.5rem 0.75rem;
  }

  .cart-count {
    padding: 0.1rem 0.5rem;
  }
}

@media (max-width: 480px) {
  .nav-logo {
    font-size: 1.25rem;
  }

  .nav-link:not(.nav-link-cart) {
    display: none;
  }
}
</style>