import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "product-image-container" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "product-info" }
const _hoisted_4 = { class: "product-header" }
const _hoisted_5 = { class: "product-name" }
const _hoisted_6 = { class: "product-category" }
const _hoisted_7 = { class: "product-description" }
const _hoisted_8 = { class: "product-footer" }
const _hoisted_9 = { class: "price" }


import { useRouter } from 'vue-router'
import type { Product } from '../../types/interfaces'


const __default__ = {
  name: 'ProductCard'
}


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    product: {}
  },
  setup(__props: any) {

const props = __props

const router = useRouter()

const navigateToDetail = () => {
  router.push(`/products/${props.product.id}`)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "card-base product-card",
    onClick: navigateToDetail
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.product.images[0],
        alt: _ctx.product.name,
        class: "product-image"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.product.name), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.product.category), 1)
      ]),
      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.product.description), 1),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("p", _hoisted_9, "$" + _toDisplayString(_ctx.product.price.toFixed(2)), 1),
        _cache[0] || (_cache[0] = _createElementVNode("button", { class: "view-details-button" }, [
          _createTextVNode(" View Details "),
          _createElementVNode("span", { class: "arrow-icon" }, "→")
        ], -1))
      ])
    ])
  ]))
}
}

})