import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "header-container" }
const _hoisted_2 = { class: "nav-wrapper" }
const _hoisted_3 = { class: "nav-links" }
const _hoisted_4 = {
  key: 0,
  class: "cart-count"
}

import { useCartStore } from '../../stores/cart'


const __default__ = {
  name: 'TheHeader'
}


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

const cartStore = useCartStore()

const navLinks = [
  { path: '/', label: 'Home' },
  { path: '/products', label: 'Products' },
  { path: '/cart', label: 'Cart' }
]

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/",
        class: "nav-logo"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" Wrist Twist "),
          _createElementVNode("span", { class: "nav-logo-accent" }, ".", -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(navLinks, (link) => {
          return _createVNode(_component_router_link, {
            key: link.path,
            to: link.path,
            class: _normalizeClass(["nav-link", { 'nav-link-cart': link.path === '/cart' }])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(link.label) + " ", 1),
              (link.path === '/cart')
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(cartStore).totalItems), 1))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["to", "class"])
        }), 64))
      ])
    ])
  ]))
}
}

})