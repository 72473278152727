<template>
  <main class="product-detail">
    <div class="product-container">
      <div class="product-images">
        <img :src="product?.images[0]" :alt="product?.name" class="main-image">
        <div class="image-gallery">
          <img 
            v-for="(image, index) in product?.images.slice(1)" 
            :key="index" 
            :src="image" 
            :alt="product?.name"
            class="thumbnail"
          >
        </div>
      </div>

      <div class="product-info">
        <div class="product-header">
          <h1 class="product-name">{{ product?.name }}</h1>
          <p class="product-category">{{ product?.category }}</p>
        </div>

        <p class="product-price">${{ product?.price.toFixed(2) }}</p>
        
        <div class="product-description">
          <p>{{ product?.description }}</p>
          <ul class="features-list">
            <li v-for="feature in product?.features" :key="feature">
              {{ feature }}
            </li>
          </ul>
        </div>

        <div class="product-options">
          <div class="option-group">
            <label>Size</label>
            <div class="size-options">
              <button 
                v-for="size in sizes" 
                :key="size"
                :class="['size-button', selectedSize === size ? 'selected' : '']"
                @click="selectedSize = size"
              >
                {{ size }}
              </button>
            </div>
          </div>

          <div class="option-group">
            <label>Quantity</label>
            <div class="quantity-selector">
              <button @click="decrementQuantity">-</button>
              <span>{{ quantity }}</span>
              <button @click="incrementQuantity">+</button>
            </div>
          </div>
        </div>

        <button 
          class="add-to-cart-button"
          :disabled="!selectedSize"
        >
          Add to Cart - ${{ (product?.price ?? 0) * quantity }}
        </button>
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useProductStore } from '../stores/products'
import type { Product } from '../types/interfaces'

const route = useRoute()
const productStore = useProductStore()
const product = ref<Product | null>(null)
const selectedSize = ref('')
const quantity = ref(1)

const sizes = ['Small', 'Medium', 'Large']

onMounted(async () => {
  await productStore.fetchProducts()
  product.value = productStore.products.find(p => p.id === route.params.id)
})

const incrementQuantity = () => {
  if (quantity.value < 10) quantity.value++
}

const decrementQuantity = () => {
  if (quantity.value > 1) quantity.value--
}
</script>

<style scoped>
.product-detail {
  background-color: #121212;
  min-height: calc(100vh - 70px);
  padding: var(--spacing-lg) var(--spacing-md);
}

.product-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  background-color: var(--light-color);
  border-radius: var(--border-radius);
  padding: 2rem;
}

.product-images {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.main-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: var(--border-radius);
}

.image-gallery {
  display: flex;
  gap: 1rem;
}

.thumbnail {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.thumbnail:hover {
  opacity: 0.8;
}

.product-header {
  margin-bottom: 1rem;
}

.product-name {
  font-size: 2rem;
  color: var(--secondary-color);
  margin-bottom: 0.5rem;
}

.product-category {
  color: #666;
  text-transform: capitalize;
}

.product-price {
  font-size: 2rem;
  color: var(--primary-color);
  font-weight: bold;
  margin-bottom: 2rem;
}

.product-description {
  color: #4a4a4a;
  margin-bottom: 2rem;
}

.features-list {
  margin-top: 1rem;
  padding-left: 1.5rem;
}

.features-list li {
  margin-bottom: 0.5rem;
}

.product-options {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.option-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--secondary-color);
  font-weight: 600;
}

.size-options {
  display: flex;
  gap: 1rem;
}

.size-button {
  padding: 0.5rem 1.5rem;
  border: 2px solid var(--secondary-color);
  background: transparent;
  color: var(--secondary-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: all 0.3s ease;
}

.size-button.selected {
  background-color: var(--secondary-color);
  color: var(--light-color);
}

.quantity-selector {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.quantity-selector button {
  width: 2rem;
  height: 2rem;
  border: none;
  background-color: var(--secondary-color);
  color: var(--light-color);
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.quantity-selector button:hover {
  opacity: 0.8;
}

.add-to-cart-button {
  width: 100%;
  padding: 1rem;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  border-radius: var(--border-radius);
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.add-to-cart-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.add-to-cart-button:not(:disabled):hover {
  opacity: 0.9;
}

@media (max-width: 1024px) {
  .product-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

@media (max-width: 640px) {
  .main-image {
    height: 300px;
  }

  .product-name {
    font-size: 1.5rem;
  }

  .product-price {
    font-size: 1.5rem;
  }
}
</style> 