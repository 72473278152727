<template>
  <main>
    <section class="hero-section">
      <div class="hero-content">
        <h1 class="hero-title">
          Discover Elegant
          <span class="accent-text">Gold Bracelets</span>
        </h1>
        <p class="hero-subtitle">Handcrafted with precision, designed for luxury</p>
        <div class="hero-actions">
          <router-link to="/products" class="cta-button">
            Explore Collection
          </router-link>
        </div>
        <button @click="scrollToFeatured" class="scroll-button">
          <span class="scroll-text">Discover Featured</span>
          <span class="scroll-icon"></span>
        </button>
      </div>
    </section>

    <section id="featured" class="featured-section">
      <div class="section-header">
        <h2 class="section-title">Featured Collections</h2>
        <p class="section-subtitle">Curated selection of our finest pieces</p>
      </div>
      
      <div class="featured-container">
        <div class="featured-grid">
          <ProductCard 
            v-for="product in featuredProducts" 
            :key="product.id" 
            :product="product"
          />
        </div>
        
        <div class="view-all-container">
          <router-link to="/products" class="view-all-button">
            View All Collections
            <span class="arrow-icon">→</span>
          </router-link>
        </div>
      </div>
    </section>
  </main>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import ProductCard from '../components/product/ProductCard.vue'
import { useProductStore } from '../stores/products'
import type { Product } from '../types/interfaces'

const productStore = useProductStore()
const featuredProducts = ref<Product[]>([])

onMounted(() => {
  productStore.fetchProducts()
  featuredProducts.value = productStore.products.slice(0, 3)
})

const scrollToFeatured = () => {
  document.getElementById('featured')?.scrollIntoView({ 
    behavior: 'smooth',
    block: 'start'
  })
}
</script>

<style scoped>
.hero-section {
  height: calc(100vh - 70px);
  background: linear-gradient(
    to right,
    rgba(18, 18, 18, 0.9),
    rgba(18, 18, 18, 0.7)
  ),
  url('https://images.unsplash.com/photo-1611591437281-460bfbe1220a') center/cover;
  display: flex;
  align-items: center;
  position: relative;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 var(--spacing-md);
  text-align: center;
  color: var(--light-color);
}

.hero-title {
  font-size: clamp(2.5rem, 5vw, 4rem);
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: var(--spacing-md);
  letter-spacing: -0.5px;
}

.accent-text {
  display: block;
  color: var(--primary-color);
  font-size: clamp(3rem, 6vw, 5rem);
}

.hero-subtitle {
  font-size: clamp(1rem, 2vw, 1.25rem);
  color: #9ca3af;
  margin-bottom: var(--spacing-lg);
  line-height: 1.6;
}

.hero-actions {
  margin-bottom: var(--spacing-lg);
}

.cta-button {
  display: inline-block;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 1rem 2.5rem;
  border-radius: 9999px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  border: 2px solid var(--primary-color);
}

.cta-button:hover {
  background-color: transparent;
  color: var(--primary-color);
}

.scroll-button {
  background: none;
  border: none;
  color: #9ca3af;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin: 0 auto;
  padding: 1rem;
  transition: all 0.3s ease;
}

.scroll-text {
  font-size: 0.875rem;
  font-weight: 500;
}

.scroll-icon {
  width: 24px;
  height: 24px;
  border: 2px solid currentColor;
  border-radius: 50%;
  position: relative;
  animation: bounce 2s infinite;
}

.scroll-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  border-right: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  transform: translate(-50%, -50%) rotate(45deg);
}

.scroll-button:hover {
  color: var(--primary-color);
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.featured-section {
  padding: var(--spacing-lg) var(--spacing-md);
  background-color: #121212;
}

.section-header {
  text-align: center;
  margin-bottom: var(--spacing-lg);
}

.section-title {
  color: var(--light-color);
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: var(--spacing-sm);
}

.section-subtitle {
  color: #9ca3af;
  font-size: 1.1rem;
}

.featured-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-md);
}

.featured-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: var(--spacing-lg);
}

.view-all-container {
  text-align: center;
  margin-top: var(--spacing-lg);
}

.view-all-button {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  background-color: transparent;
  color: var(--primary-color);
  padding: 1rem 2rem;
  border: 2px solid var(--primary-color);
  border-radius: 9999px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.arrow-icon {
  transition: transform 0.3s ease;
}

.view-all-button:hover {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.view-all-button:hover .arrow-icon {
  transform: translateX(4px);
}

@media (max-width: 1024px) {
  .featured-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .featured-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}
</style> 