<template>
  <main class="products-page">
    <section class="products-header">
      <h1>Our Collection</h1>
      <p>Discover our handcrafted gold bracelets</p>
    </section>

    <section class="products-grid">
      <ProductCard 
        v-for="product in products" 
        :key="product.id" 
        :product="product"
      />
    </section>
  </main>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import ProductCard from '../components/product/ProductCard.vue'
import { useProductStore } from '../stores/products'
import type { Product } from '../types/interfaces'

const productStore = useProductStore()
const products = ref<Product[]>([])

onMounted(() => {
  productStore.fetchProducts()
  products.value = productStore.products
})
</script>

<style scoped>
.products-page {
  background-color: #121212;
  min-height: calc(100vh - 70px);
  padding: var(--spacing-lg) var(--spacing-md);
}

.products-header {
  text-align: center;
  margin-bottom: var(--spacing-lg);
  color: var(--light-color);
}

.products-header h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: var(--spacing-sm);
}

.products-header p {
  color: #9ca3af;
  font-size: 1.1rem;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--spacing-md);
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-md);
}

@media (max-width: 768px) {
  .products-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media (max-width: 480px) {
  .products-grid {
    grid-template-columns: 1fr;
  }

  .products-header h1 {
    font-size: 2rem;
  }
}
</style> 