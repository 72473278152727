import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "product-detail" }
const _hoisted_2 = { class: "product-container" }
const _hoisted_3 = { class: "product-images" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "image-gallery" }
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "product-info" }
const _hoisted_8 = { class: "product-header" }
const _hoisted_9 = { class: "product-name" }
const _hoisted_10 = { class: "product-category" }
const _hoisted_11 = { class: "product-price" }
const _hoisted_12 = { class: "product-description" }
const _hoisted_13 = { class: "features-list" }
const _hoisted_14 = { class: "product-options" }
const _hoisted_15 = { class: "option-group" }
const _hoisted_16 = { class: "size-options" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "option-group" }
const _hoisted_19 = { class: "quantity-selector" }
const _hoisted_20 = ["disabled"]

import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useProductStore } from '../stores/products'
import type { Product } from '../types/interfaces'


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductDetailView',
  setup(__props) {

const route = useRoute()
const productStore = useProductStore()
const product = ref<Product | null>(null)
const selectedSize = ref('')
const quantity = ref(1)

const sizes = ['Small', 'Medium', 'Large']

onMounted(async () => {
  await productStore.fetchProducts()
  product.value = productStore.products.find(p => p.id === route.params.id)
})

const incrementQuantity = () => {
  if (quantity.value < 10) quantity.value++
}

const decrementQuantity = () => {
  if (quantity.value > 1) quantity.value--
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: product.value?.images[0],
          alt: product.value?.name,
          class: "main-image"
        }, null, 8, _hoisted_4),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(product.value?.images.slice(1), (image, index) => {
            return (_openBlock(), _createElementBlock("img", {
              key: index,
              src: image,
              alt: product.value?.name,
              class: "thumbnail"
            }, null, 8, _hoisted_6))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("h1", _hoisted_9, _toDisplayString(product.value?.name), 1),
          _createElementVNode("p", _hoisted_10, _toDisplayString(product.value?.category), 1)
        ]),
        _createElementVNode("p", _hoisted_11, "$" + _toDisplayString(product.value?.price.toFixed(2)), 1),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("p", null, _toDisplayString(product.value?.description), 1),
          _createElementVNode("ul", _hoisted_13, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(product.value?.features, (feature) => {
              return (_openBlock(), _createElementBlock("li", { key: feature }, _toDisplayString(feature), 1))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _cache[0] || (_cache[0] = _createElementVNode("label", null, "Size", -1)),
            _createElementVNode("div", _hoisted_16, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(sizes, (size) => {
                return _createElementVNode("button", {
                  key: size,
                  class: _normalizeClass(['size-button', selectedSize.value === size ? 'selected' : '']),
                  onClick: ($event: any) => (selectedSize.value = size)
                }, _toDisplayString(size), 11, _hoisted_17)
              }), 64))
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _cache[1] || (_cache[1] = _createElementVNode("label", null, "Quantity", -1)),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("button", { onClick: decrementQuantity }, "-"),
              _createElementVNode("span", null, _toDisplayString(quantity.value), 1),
              _createElementVNode("button", { onClick: incrementQuantity }, "+")
            ])
          ])
        ]),
        _createElementVNode("button", {
          class: "add-to-cart-button",
          disabled: !selectedSize.value
        }, " Add to Cart - $" + _toDisplayString((product.value?.price ?? 0) * quantity.value), 9, _hoisted_20)
      ])
    ])
  ]))
}
}

})