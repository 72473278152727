import type { Product } from '../types/interfaces'

export const mockProducts: Product[] = [
  {
    id: '1',
    name: 'Royal Elegance Bracelet',
    description: 'Handcrafted 18K gold bracelet with intricate royal patterns',
    price: 1299.99,
    images: [
      'https://images.unsplash.com/photo-1611591437281-460bfbe1220a?w=800',
      'https://images.unsplash.com/photo-1599643478518-a784e5dc4c8f?w=800'
    ],
    category: 'luxury',
    inStock: true,
    features: [
      '18K Gold',
      'Hand-polished finish',
      'Adjustable size',
      'Signature clasp'
    ]
  },
  {
    id: '2',
    name: 'Twisted Gold Chain',
    description: 'Modern twisted chain design in solid gold',
    price: 899.99,
    images: [
      'https://images.unsplash.com/photo-1599459182681-c938b7f65af0?w=800',
      'https://images.unsplash.com/photo-1599459182584-465e3c0b0316?w=800'
    ],
    category: 'modern',
    inStock: true,
    features: [
      'Solid gold',
      'Twisted design',
      'Security lock',
      'Lightweight'
    ]
  },
  {
    id: '3',
    name: 'Diamond Accent Bangle',
    description: 'Classic gold bangle with diamond accents',
    price: 1599.99,
    images: [
      'https://images.unsplash.com/photo-1602751584514-9e02475394b9?w=800',
      'https://images.unsplash.com/photo-1602751584553-8ba49c8c8fac?w=800'
    ],
    category: 'luxury',
    inStock: false,
    features: [
      'Diamond accents',
      'Solid gold construction',
      'Push clasp',
      'Comfort fit'
    ]
  },
  {
    id: '4',
    name: 'Minimalist Gold Band',
    description: 'Simple yet elegant solid gold band',
    price: 699.99,
    images: [
      'https://images.unsplash.com/photo-1603561596112-0a132b757442?w=800',
      'https://images.unsplash.com/photo-1603561595671-0e0537da8a82?w=800'
    ],
    category: 'minimal',
    inStock: true,
    features: [
      'Minimalist design',
      'High polish finish',
      'Seamless closure',
      'Stackable'
    ]
  },
  {
    id: '5',
    name: 'Vintage Pattern Cuff',
    description: 'Wide cuff bracelet with vintage-inspired patterns',
    price: 1099.99,
    images: [
      'https://images.unsplash.com/photo-1603561591411-07134e71a2a9?w=800',
      'https://images.unsplash.com/photo-1603561591695-0e5a7f624294?w=800'
    ],
    category: 'vintage',
    inStock: true,
    features: [
      'Wide cuff design',
      'Vintage patterns',
      'Adjustable fit',
      'Statement piece'
    ]
  },
  {
    id: '6',
    name: 'Modern Link Bracelet',
    description: 'Contemporary linked design in polished gold',
    price: 899.99,
    images: [
      'https://images.unsplash.com/photo-1603561589151-43f9974aa62b?w=800',
      'https://images.unsplash.com/photo-1603561589982-79927b3ed2b4?w=800'
    ],
    category: 'modern',
    inStock: true,
    features: [
      'Modern link design',
      'Double safety clasp',
      'High polish finish',
      'Comfortable wear'
    ]
  }
] 