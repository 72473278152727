import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "products-page" }
const _hoisted_2 = { class: "products-grid" }

import { onMounted, ref } from 'vue'
import ProductCard from '../components/product/ProductCard.vue'
import { useProductStore } from '../stores/products'
import type { Product } from '../types/interfaces'


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductsView',
  setup(__props) {

const productStore = useProductStore()
const products = ref<Product[]>([])

onMounted(() => {
  productStore.fetchProducts()
  products.value = productStore.products
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("section", { class: "products-header" }, [
      _createElementVNode("h1", null, "Our Collection"),
      _createElementVNode("p", null, "Discover our handcrafted gold bracelets")
    ], -1)),
    _createElementVNode("section", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(products.value, (product) => {
        return (_openBlock(), _createBlock(ProductCard, {
          key: product.id,
          product: product
        }, null, 8, ["product"]))
      }), 128))
    ])
  ]))
}
}

})