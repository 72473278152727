<template>
  <div class="card-base product-card" @click="navigateToDetail">
    <div class="product-image-container">
      <img :src="product.images[0]" :alt="product.name" class="product-image">
    </div>
    <div class="product-info">
      <div class="product-header">
        <h3 class="product-name">{{ product.name }}</h3>
        <p class="product-category">{{ product.category }}</p>
      </div>
      <p class="product-description">{{ product.description }}</p>
      <div class="product-footer">
        <p class="price">${{ product.price.toFixed(2) }}</p>
        <button class="view-details-button">
          View Details
          <span class="arrow-icon">→</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'
import type { Product } from '../../types/interfaces'

const props = defineProps<{
  product: Product
}>()

const router = useRouter()

const navigateToDetail = () => {
  router.push(`/products/${props.product.id}`)
}
</script>

<script lang="ts">
export default {
  name: 'ProductCard'
}
</script>

<style scoped>
.product-card {
  background-color: var(--light-color);
  display: flex;
  flex-direction: column;
  height: 500px; /* Fixed height for consistency */
}

.product-image-container {
  width: 100%;
  height: 250px; /* Fixed height */
  overflow: hidden;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.product-info {
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.product-header {
  margin-bottom: var(--spacing-sm);
}

.product-name {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--secondary-color);
  margin-bottom: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-category {
  color: #666;
  font-size: 0.875rem;
  text-transform: capitalize;
}

.product-description {
  color: #4a4a4a;
  font-size: 0.95rem;
  line-height: 1.5;
  /* Limit to 2 lines */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  flex-shrink: 0;
}

.product-footer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.price {
  color: var(--primary-color);
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
}

.view-details-button {
  width: 100%;
  padding: 0.75rem;
  background-color: transparent;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  border-radius: var(--border-radius);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.view-details-button:hover {
  background-color: var(--primary-color);
  color: var(--light-color);
}

.arrow-icon {
  transition: transform 0.3s ease;
}

.view-details-button:hover .arrow-icon {
  transform: translateX(4px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .product-card {
    height: 450px;
  }

  .product-image-container {
    height: 200px;
  }

  .product-info {
    padding: var(--spacing-sm);
  }

  .product-name {
    font-size: 1.1rem;
  }

  .price {
    font-size: 1.25rem;
  }
}
</style>